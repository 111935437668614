
import {
  computed, defineComponent, nextTick, ref,
} from 'vue';
import webcamProvider from '@/services/WebcamProvider';
import { DatabaseEntry } from '@/services/MotionDatabase';
import WorkflowMenu from '@/components/screens/WorkflowMenu.vue';
import CreateWorkflowScreen from '@/components/screens/CreateWorkflowScreen.vue';
import MiniLessonPlayer from '@/components/elements/MiniLessonPlayer.vue';
import LayoutTest from '@/components/elements/LayoutTest.vue';
import KeyframeSelectorTool from '@/components/tools/KeyframeSelectorTool.vue';
import CameraSurface from './components/CameraSurface.vue';
import OnboardingUI from './components/OnboardingUI.vue';
import MainMenu from './components/screens/MainMenu.vue';
// import LearningScreen from './components/screens/LearningScreen.vue';
import MiniLesson from './model/MiniLesson';
import WebcamPromptCard from './components/elements/WebcamPromptCard.vue';

import PoseDrawerTest from './components/screens/PoseDrawerTest.vue';
import CreateLessonScreen from './components/screens/CreateLessonScreen.vue';
import workflowManager from './services/WorkflowManager';
import optionsManager from './services/OptionsManager';

const State = {
  LayoutTest: 'LayoutTest',
  MainMenu: 'MainMenu',
  PromptStartWebcam: 'PromptStartWebcam',
  StartingWebcam: 'StartingWebcam',
  LoadingTracking: 'LoadingTracking',
  Onboarding: 'Onboarding',
  LessonActive: 'LessonActive',
  WorkflowActive: 'WorkflowActive',
  PoseDrawingTester: 'PoseDrawingTester',
  CreateLesson: 'CreateLesson',
  CreateWorkflow: 'CreateWorkflow',
  KeyframeSelectorTool: 'KeyframeSelectorTool',
};

export default defineComponent({
  name: 'App',
  components: {
    CameraSurface,
    OnboardingUI,
    MainMenu,
    LayoutTest,
    WorkflowMenu,
    // LearningScreen,
    KeyframeSelectorTool,
    WebcamPromptCard,
    PoseDrawerTest,
    CreateLessonScreen,
    CreateWorkflowScreen,
    MiniLessonPlayer,
  },
  setup() {
    const state = ref(State.MainMenu);
    const cameraSurface = ref(null as typeof CameraSurface | null);
    const hasCompletedOnboarding = ref(false);

    const currentVideo = ref(null as DatabaseEntry | null);
    const currentLesson = ref(null as MiniLesson | null);
    const keyframes = ref([] as number[]);

    const showCameraSurface = computed(() => [
      State.PromptStartWebcam,
      State.StartingWebcam,
      State.LoadingTracking,
      State.Onboarding,
      // State.LessonActive,
    ].indexOf(state.value) !== -1);

    function lessonSelected(videoEntry: DatabaseEntry, lesson: MiniLesson) {
      currentVideo.value = videoEntry;
      currentLesson.value = lesson;

      state.value = State.LessonActive;
      // if (webcamProvider.webcamStatus.value === 'running') state.value = State.LessonActive;
      // else state.value = State.PromptStartWebcam;
    }

    function startKeyframeSelection(videoEntry: DatabaseEntry, kfs: number[]) {
      console.log('startKeyframeSelection', videoEntry, kfs);
      keyframes.value = kfs;
      currentVideo.value = videoEntry;
      state.value = State.KeyframeSelectorTool;
    }

    function goHome() {
      state.value = State.MainMenu;
      currentVideo.value = null;
      currentLesson.value = null;
    }

    async function startTracking() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      nextTick(() => {
        const camSurface: any = cameraSurface.value;
        state.value = State.LoadingTracking;
        camSurface.startTracking();
      });
    }

    async function startWebcam() {
      console.log('Starting Webcam');

      if (webcamProvider.webcamStatus.value !== 'running') {
        state.value = State.StartingWebcam;

        try {
          await webcamProvider.startWebcam();
        } catch (e) {
          console.error('Error starting webcam: ', e);
          // eslint-disable-next-line no-alert
          alert('Failed to start the webcam\n\nPlease ensure this is the only app using the camera and that you\'ve allowed camera access in your browser.');
          state.value = State.MainMenu;
          return;
        }
      }

      state.value = State.LoadingTracking;
      try {
        await startTracking();
      } catch (e) {
        console.error("Couldn't start tracking", e);
      }
    }

    function onTrackingAttained() {
      console.log('Tracking attained');
      if (hasCompletedOnboarding.value) {
        state.value = State.LessonActive;
      } else state.value = State.Onboarding;
    }

    return {
      showCameraSurface,
      currentVideo,
      currentLesson,
      lessonSelected,
      goHome,
      startWebcam,
      onTrackingAttained,
      startKeyframeSelection,
      keyframes,
      state,
      cameraSurface,
      State,
      startedWithWorkflow: ref(false),
      isTest: optionsManager.isTest,
      participantId: optionsManager.participantId,
    };
  },
  mounted() {
    // If app was launched with a workflowId, skip straight
    // to that workflow
    if (optionsManager.workflowId.value) {
      if (this.startWorkflow(optionsManager.workflowId.value)) {
        this.startedWithWorkflow = true;
      }
    }
  },
  methods: {
    poseDrawerSelected() {
      this.state = State.PoseDrawingTester;
    },
    createLessonSelected(dance: DatabaseEntry) {
      this.currentVideo = dance;
      this.state = State.CreateLesson;
    },
    startWorkflow(workflowId: string) {
      console.log('Starting workflow', workflowId);
      if (workflowManager.setActiveFlow(workflowId)) {
        this.state = State.WorkflowActive;
        return true;
      }
      return false;
    },
  },
});
