
import db, { DatabaseEntry } from '@/services/MotionDatabase';
import {
  computed, defineComponent, ref,
} from 'vue';

import VideoPlayer from '@/components/elements/VideoPlayer.vue';

export default defineComponent({
  name: 'PoseDrawerTest',
  components: { VideoPlayer },
  setup() {
    const { motions } = db;
    const videoPlayer = ref(null as null | typeof VideoPlayer);
    const dropdownOpen = ref(false);

    const activeVideoEntry = ref(null as null | DatabaseEntry);
    const clipName = computed(() => activeVideoEntry.value?.clipName ?? 'No clip selected');
    const videoSrc = computed(() => activeVideoEntry.value?.videoSrc ?? '');
    const videoTime = ref(0);
    const fps = ref(30);
    const error = ref(null as string | null);

    async function loadMotion(entry: DatabaseEntry) {
      activeVideoEntry.value = entry;
      error.value = null;
      dropdownOpen.value = false;
      fps.value = entry.fps;
    }

    function playVideo() {
      const vidPlayer = videoPlayer.value;
      if (!vidPlayer) return;
      vidPlayer.playVideo(0, 1000, 0.5);
    }
    function pauseVideo() {
      const vidPlayer = videoPlayer.value;
      if (!vidPlayer) return;
      vidPlayer.pauseVideo();
    }

    function onProgress(time: number) {
      videoTime.value = time;
    }

    return {
      motions,
      activeVideoEntry,
      videoPlayer,
      error,
      loadMotion,
      dropdownOpen,
      clipName,
      videoSrc,
      playVideo,
      pauseVideo,
      onProgress,
    };
  },
  methods: {
    changeTime(event: InputEvent) {
      const vidPlayer = this.videoPlayer;
      if (!vidPlayer) return;
      const time = (event.target as HTMLInputElement).valueAsNumber;
      if (!Number.isNaN(time)) vidPlayer.setTime(time);
    },
  },
});
