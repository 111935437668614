
import { defineComponent } from 'vue';
import db, { DatabaseEntry } from '@/services/MotionDatabase';
import keyframeOptions from '@/model/keyframeOptions.json';

export default defineComponent({
  name: 'LessonCard',
  props: {
    motion: {
      type: Object,
    },
    canCreateLesson: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    lessons() {
      if (!this.motion) return [];
      const motion = this.motion as DatabaseEntry;
      const lessons = db.getLessons(motion);
      return lessons ?? [];
    },
    keyframeOptions() {
      if (!this.motion) return [];
      const motion = this.motion as DatabaseEntry;
      return keyframeOptions.filter((option) => option.clipName === motion.clipName);
    },
  },
  emits: ['lesson-selected', 'create-lesson-selected', 'closed', 'keyframeselectortool-selected'],
});
