

import {
  computed,
  defineComponent, nextTick, ref, toRefs, watchEffect,
} from 'vue';

import Constants from '@/services/Constants';
import MiniLessonPlayer from '@/components/elements/MiniLessonPlayer.vue';
import db, { createBlankActivity, createBlankLesson, DatabaseEntry } from '@/services/MotionDatabase';
import MiniLesson, { MiniLessonActivity, PauseInfo, TimedInstruction } from '@/model/MiniLesson';
import Utils from '@/services/Utils';
import SegmentedProgressBar, { ProgressSegmentData, calculateProgressSegments } from '@/components/elements/SegmentedProgressBar.vue';
import KeyframeSelectorTool from '@/components/tools/KeyframeSelectorTool.vue';

const LessonCreationState = Object.freeze({
  SelectOpenLesson: 'SelectOpenLesson',
  ModifyLesson: 'ModifyLesson',
});

interface SegmentInfo {
  isFocused: boolean;
  startTime: number;
  endTime: number;
}

export default defineComponent({
  name: 'CreateLessonScreen',
  components: {
    MiniLessonPlayer,
    SegmentedProgressBar,
    KeyframeSelectorTool,
  },
  props: {
    motion: {
      type: Object,
      required: true,
    },
    saveReference: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    showExportButton: {
      type: Boolean,
      default: true,
    },
    lessonToEdit: {
      type: Object,
      default: null,
    },
    saveToDatabase: {
      type: Boolean,
      default: true,
    },
    disableEditingExisting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['back-selected', 'lesson-saved'],
  data() {
    return {
      newPauseTime: 0,
      newSegmentVal: 0,
      activeLessonSelectionIndex: -1,
      activePauseIndex: 0,
      activeTimedInstructionIndex: 0,
      keyframeSelectorToolActive: false,
      // activityProgress: 0,
    };
  },
  computed: {
    activeActivityKeyframes: {
      get(): number[] {
        return (this as any).activeActivity?.keyframes ?? [];
      },
      set(newVal: number[]) {
        this.activeActivity.keyframes = newVal;
      },
    },
    displayMode: {
      get() {
        const demoVisual = this.activeActivity.demoVisual ?? 'video';
        const userVisual = this.activeActivity.userVisual ?? 'none';
        if (demoVisual === 'video' && userVisual === 'none') return 'video-demo';
        if (demoVisual === 'skeleton' && userVisual === 'video') return 'skeleton-overlay';
        return 'other';
      },
      set(newVal: 'other' | 'video-demo' | 'skeleton-overlay') {
        if (newVal === 'video-demo') {
          this.activeActivity.demoVisual = 'video';
          this.activeActivity.userVisual = 'none';
        } else if (newVal === 'skeleton-overlay') {
          this.activeActivity.demoVisual = 'skeleton';
          this.activeActivity.userVisual = 'video';
        }
      },
    },
    activeActivityFocusedSegments() {
      const segBreaks = (this as any).lessonUnderConstruction.segmentBreaks as number[];
      const segs: number[] = (this as any).activeActivity?.focusedSegments ?? [];
      const segsSet = new Set(segs);
      const boolArray = Utils.range((this as any).lessonUnderConstruction.segmentBreaks.length - 1)
        .map((i) => ({
          isFocused: segsSet.has(i),
          startTime: segBreaks[i],
          endTime: segBreaks[i + 1],
        } as SegmentInfo));
      return boolArray;
    },
    activeLessonSelection(): MiniLesson | null {
      return this.lessons[this.activeLessonSelectionIndex] ?? null;
    },
    lessonInDatabase(): boolean {
      return db.hasLesson(this.lessonUnderConstruction);
    },
    canDeleteLesson(): boolean {
      return this.lessonInDatabase && this.lessonUnderConstruction.source === 'custom';
    },
    lessonStartTime(): number {
      return this.lessonUnderConstruction?.segmentBreaks[0] ?? 0;
    },
    lessonEndTime(): number {
      return this.lessonUnderConstruction.segmentBreaks[this.lessonUnderConstruction.segmentBreaks.length - 1] ?? this.motion?.duration ?? 1;
    },
    lessons() {
      if (!this.motion) return [];
      const motion = this.motion as DatabaseEntry;
      const lessons = db.getLessons(motion);
      return lessons ?? [];
    },
    progressSegments(): ProgressSegmentData[] {
      if (this.activeActivity && this.lessonUnderConstruction) return calculateProgressSegments(this.lessonUnderConstruction, this.activeActivity);
      return [];
    },
    activePause(): null | PauseInfo {
      return ((this as any).activeActivity.pauses ?? [])[(this as any).activePauseIndex] ?? null;
    },
    activeTimedInstruction(): null | TimedInstruction {
      return ((this as any).activeActivity.timedInstructions ?? [])[(this as any).activeTimedInstructionIndex] ?? null;
    },
    hasNextActivity(): boolean {
      return this.activeActivityIndex + 1 < this.lessonUnderConstruction.activities.length;
    },
    hasPreviousActivity(): boolean {
      return this.activeActivityIndex > 0;
    },
    canEditActiveLesson() {
      const acLes = this.activeLessonSelection as MiniLesson;
      return acLes?.source === 'custom';
    },
  },
  mounted() {
    const passedInLesson = this.$props.lessonToEdit as MiniLesson | null;
    if (passedInLesson) {
      console.log('CreateLessonScreen:: using passed in lesson', passedInLesson);
      this.startCreation(false, Utils.deepCopy(passedInLesson));
      nextTick(() => { this.isDirty = false; });
    } else if (this.lessons.length === 0) {
      console.log('CreateLessonScreen:: creating blank lesson (no templates available)');
      this.startCreation(true);
    }
  },
  setup(props) {
    const { motion } = toRefs(props);
    const isDirty = ref(false);
    const typedMotion = computed(() => motion.value as unknown as DatabaseEntry);
    const state = ref(LessonCreationState.SelectOpenLesson);
    const lessonUnderConstruction = ref(createBlankLesson(typedMotion.value));
    const activeActivityIndex = ref(0);
    const activeActivity = computed(() => lessonUnderConstruction.value.activities[activeActivityIndex.value]);

    watchEffect(() => {
      if (activeActivity.value.startTime < 0) activeActivity.value.startTime = 0;
      if (activeActivity.value.endTime > motion.value.duration) activeActivity.value.endTime = motion.value.duration;
      if (+activeActivity.value.endTime < +activeActivity.value.startTime) activeActivity.value.endTime = activeActivity.value.startTime;
    });

    return {
      isDirty,
      state,
      typedMotion,
      LessonCreationState,
      lessonUnderConstruction,
      activeActivityIndex,
      activeActivity,
      Constants,
    };
  },
  watch: {
    activeActivityIndex: {
      handler(newVal: number) {
        (this.$refs.miniLessonPlayer as any).activeActivityIndex = newVal;
      },
    },
    lessonUnderConstruction: {
      handler() {
        this.isDirty = true;
      },
      deep: true,
    },
    'lessonUnderConstruction.segmentBreaks': {
      handler() {
        this.sortSegmentBreaks();
      },
      deep: true,
    },
    'activePause.time': {
      handler() {
        this.sortPauses();
      },
    },
    'activeTimedInstruction.startTime': {
      handler() {
        this.sortTimedInstructions();
      },
    },
  },
  methods: {
    canDeleteSegment(segmentIndex: number) {
      return this.progressSegments.length > 1;
    },
    goBack() {
      // eslint-disable-next-line no-alert
      if (!this.isDirty || window.confirm('Are you sure you want to go back without saving?')) {
        this.$emit('back-selected');
      }
    },
    startCreation(asTemplate: boolean, sourceLesson?: MiniLesson) {
      const existingLesson = sourceLesson ?? this.activeLessonSelection;
      if (existingLesson && asTemplate) {
        console.log('Creating new lesson from template');
        this.lessonUnderConstruction = Utils.deepCopy(existingLesson);
        this.lessonUnderConstruction._id = Utils.uuidv4();
        this.lessonUnderConstruction.source = 'custom';
      } else if (existingLesson && !asTemplate) {
        console.log('Editing existing lesson');
        this.lessonUnderConstruction = existingLesson;
        nextTick(() => { this.isDirty = false; });
      } else {
        console.log('Creating new lesson from scratch');
        this.lessonUnderConstruction = createBlankLesson(this.typedMotion);
      }
      this.state = LessonCreationState.ModifyLesson;
    },
    addActivity(targetIndex?: number) {
      const newActivity: MiniLessonActivity = createBlankActivity(this.typedMotion, `Activity ${this.lessonUnderConstruction.activities.length + 1}`);

      if (targetIndex === undefined) {
        this.lessonUnderConstruction.activities.push(newActivity);
        this.activeActivityIndex = this.lessonUnderConstruction.activities.length - 1;
      } else {
        this.lessonUnderConstruction.activities.splice(targetIndex, 0, newActivity);
        this.activeActivityIndex = targetIndex;
      }
    },
    selectActivity(targetIndex: number) {
      this.activeActivityIndex = targetIndex;
    },
    canDeleteActivity(targetIndex: number) {
      return this.lessonUnderConstruction.activities.length > 1;
    },
    canReorderActivity(targetIndex: number, indexDelta: number) {
      const curActivity = this.lessonUnderConstruction.activities[targetIndex];
      const swapActivity = this.lessonUnderConstruction.activities[targetIndex + indexDelta];
      return (curActivity !== undefined && swapActivity !== undefined);
    },
    reorderActivity(targetIndex: number, indexDelta: number) {
      const curActivity = this.lessonUnderConstruction.activities[targetIndex];
      const swapIndex = targetIndex + indexDelta;
      const swapActivity = this.lessonUnderConstruction.activities[swapIndex];
      if (curActivity === undefined || swapActivity === undefined) {
        console.error(`Cannot reorder activities ${targetIndex} and ${swapIndex}`);
        return;
      }

      this.lessonUnderConstruction.activities[swapIndex] = curActivity;
      this.lessonUnderConstruction.activities[targetIndex] = swapActivity;
      if (this.activeActivityIndex === targetIndex) {
        this.activeActivityIndex = swapIndex;
      }
    },
    deleteActivity(targetIndex: number) {
      const indexToDelete = targetIndex ?? this.activeActivityIndex;
      const activities = this.lessonUnderConstruction.activities ?? [];
      if (activities.length < 2) {
        console.error("Can't delete activity - no activities would be left");
        return;
      }
      // eslint-disable-next-line no-alert
      if (!window.confirm('Are you sure you want to delete this activity?')) return;
      console.log('Deleting activity at index', indexToDelete);
      activities.splice(indexToDelete, 1);
      this.lessonUnderConstruction.activities = activities;
      this.activeActivityIndex = Math.max(Math.min(activities.length - 1, this.activeActivityIndex), 0);
    },
    duplicateActivity() {
      const activity = this.activeActivity;
      const lesson = this.lessonUnderConstruction;
      if (!activity || !lesson) return;
      const dupActivity = Utils.deepCopy(activity);
      lesson.activities.splice(this.activeActivityIndex, 0, dupActivity);
      this.activeActivityIndex += 1;
    },
    addPause(targetIndex?: number) {
      const pauses = this.activeActivity.pauses ?? [];
      const newPause: PauseInfo = {
        time: this.activeActivity.startTime,
        pauseDuration: Constants.DefaultPauseDuration,
      };
      if (targetIndex === undefined) {
        pauses.push(newPause);
        this.activePauseIndex = pauses.length - 1;
      } else {
        pauses.splice(targetIndex, 0, newPause);
        this.activePauseIndex = targetIndex;
      }
      this.sortPauses();
      this.activeActivity.pauses = pauses;
    },
    selectPause(targetIndex: number) {
      if (targetIndex === this.activePauseIndex) this.activePauseIndex = -1;
      else this.activePauseIndex = targetIndex;
    },
    sortPauses() {
      const pauses = this.activeActivity.pauses ?? [];
      const selectedPause = pauses[this.activePauseIndex];
      pauses.sort((a, b) => a.time - b.time);
      const newIndex = pauses.indexOf(selectedPause);
      if (newIndex >= 0) this.activePauseIndex = newIndex;
      this.activeActivity.pauses = pauses;
    },
    deletePause(pauseObj: PauseInfo) {
      const pauses = this.activeActivity.pauses ?? [];
      const pauseIndex = pauses.indexOf(pauseObj);
      // eslint-disable-next-line no-alert
      if (pauseIndex >= 0 && window.confirm('Are you sure you want to delete this pause?')) pauses.splice(pauseIndex, 1);
      this.activeActivity.pauses = pauses;
    },
    addTimedInstruction(targetIndex?: number) {
      const timedInstructions = this.activeActivity.timedInstructions ?? [];
      const newInstruction: TimedInstruction = {
        startTime: 0,
        endTime: 1,
        text: 'Do XYZ',
      };
      if (targetIndex === undefined) {
        timedInstructions.push(newInstruction);
        this.activeTimedInstructionIndex = timedInstructions.length - 1;
      } else {
        timedInstructions.splice(targetIndex, 0, newInstruction);
        this.activeTimedInstructionIndex = targetIndex;
      }
      this.sortTimedInstructions();

      this.activeActivity.timedInstructions = timedInstructions;
    },
    selectTimedInstruction(targetIndex: number) {
      if (targetIndex === this.activeTimedInstructionIndex) this.activeTimedInstructionIndex = -1;
      else this.activeTimedInstructionIndex = targetIndex;
    },
    sortTimedInstructions() {
      const timedInstructs = this.activeActivity.timedInstructions ?? [];
      const selectedTimedInstruc = timedInstructs[this.activeTimedInstructionIndex];
      timedInstructs.sort((a, b) => a.startTime - b.startTime);
      const newIndex = timedInstructs.indexOf(selectedTimedInstruc);
      if (newIndex >= 0) this.activeTimedInstructionIndex = newIndex;
      this.activeActivity.timedInstructions = timedInstructs;
    },
    deleteTimedInstruction(timedInstruction: TimedInstruction) {
      const timedInstructs = this.activeActivity.timedInstructions ?? [];
      const tiIndex = timedInstructs.indexOf(timedInstruction);
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (tiIndex >= 0 && window.confirm('Are you sure you want to delete this timed instruction?')) timedInstructs.splice(tiIndex, 1);
      this.activeActivity.timedInstructions = timedInstructs;
    },
    addSegmentBreak(breakTime: number) {
      breakTime = +breakTime;
      if (Number.isNaN(breakTime) || breakTime < 0 || breakTime > this.motion.duration) return;
      this.lessonUnderConstruction.segmentBreaks.push(breakTime);
      const newList = this.lessonUnderConstruction.segmentBreaks.sort((a, b) => a - b);
      this.lessonUnderConstruction.segmentBreaks = newList;
      this.newSegmentVal = 0;
    },
    setFocusedSegment(i: number, event: InputEvent) {
      const focusedSegments = this.activeActivity.focusedSegments ?? [];
      const curIndex = focusedSegments.indexOf(i);
      const isFocused: boolean = (event.target as any)?.checked ?? false;
      if (curIndex === -1 && isFocused) {
        focusedSegments.push(i);
        focusedSegments.sort();
      } else if (curIndex !== -1 && !isFocused) {
        focusedSegments.splice(curIndex, 1);
      }

      this.activeActivity.focusedSegments = focusedSegments;
    },
    sortSegmentBreaks() {
      this.lessonUnderConstruction.segmentBreaks = this.lessonUnderConstruction.segmentBreaks.sort((a, b) => a - b);
    },
    setSegmentBreak(i: number, event: InputEvent) {
      const val = parseFloat((event.target as any)?.value ?? '');
      if (!Number.isNaN(val) && val >= 0 && val <= this.motion.duration) {
        this.lessonUnderConstruction.segmentBreaks[i] = val;
      }
    },
    removeSegmentBreak(i: number) {
      this.lessonUnderConstruction.segmentBreaks.splice(i, 1);
    },
    // playDemo() {
    //   (this.$refs.activityVideoPlayer as any).play();
    // },
    // onProgress(progress: number) {
    //   this.activityProgress = progress;
    // },
    saveLesson() {
      if (this.$props.saveToDatabase) {
        db.saveCustomLesson(this.lessonUnderConstruction);
      }
      this.$emit('lesson-saved', this.lessonUnderConstruction);
      this.isDirty = false;
    },
    deleteLesson() {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Are you sure you want to delete this lesson?')) {
        return;
      }

      db.deleteCustomLesson(this.lessonUnderConstruction);
      this.$emit('back-selected');
    },
    exportLesson() {
      Utils.PromptDownloadFile(`${this.lessonUnderConstruction.header.lessonTitle}.lesson.json`, JSON.stringify(this.lessonUnderConstruction));
    },
  },
});
