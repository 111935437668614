

import {
  defineComponent, onBeforeUnmount, onMounted, onUpdated, ref,
} from 'vue';
import webcamProvider from '@/services/WebcamProvider';
import { setupMediaPipeListening } from '@/services/EventHub';
import { MpHolisticResults } from '@/services/MediaPipeTypes';
import DrawingSurface from './DrawingSurface.vue';
import { isTracking as mpIsTracking, StartTracking } from '../services/MediaPipe';

export default defineComponent({
  name: 'CameraSurface',
  components: {
    DrawingSurface,
  },
  data() {
    return {
      trackingIntervalId: -1,
    };
  },
  setup(props, { emit }) {
    // const { enableDrawing } = toRefs(props);
    const hasStartedTracking = ref(false);
    const isActivelyTracking = ref(false);
    const hasResults = ref(false);
    const trackingResults = ref({});

    let timerId = -1;
    onMounted(() => {
      timerId = window.setInterval(() => {
        isActivelyTracking.value = mpIsTracking();
      }, 500);
    });
    onBeforeUnmount(() => {
      clearInterval(timerId);
    });

    function onResults(results: MpHolisticResults) {
      if (!hasResults.value) {
        emit('tracking-attained');
        console.log('Got Results', results);
        hasResults.value = true;
      }
      trackingResults.value = results;
      // this.$refs.drawingSurface.draw(results, canvasCtx);
    }

    setupMediaPipeListening(onResults);

    return {
      hasStartedTracking,
      isActivelyTracking,
      hasResults,
      trackingResults,
    };
  },
  methods: {
    startTracking() {
      if (this.trackingIntervalId !== -1) return;

      const videoE = this.$refs.videoE as HTMLVideoElement;
      const whenReady = () => {
        if (this.hasStartedTracking) return;
        this.hasStartedTracking = true;
        console.log('Video loaded. Starting tracking...');

        StartTracking(
            this.$refs.videoE as HTMLVideoElement,
        );

        videoE.onloadedmetadata = null;
      };

      this.trackingIntervalId = window.setInterval(() => {

        if (this.hasStartedTracking) {
          clearInterval(this.trackingIntervalId);
          this.trackingIntervalId = -1;
        }

        if (videoE.readyState === 4) {
          whenReady();
          return;
        }

        console.log('Video not ready, will start tracking when loaded.');
      }, 100);

      webcamProvider.connectVideoElement(videoE);
    },
  },
});
