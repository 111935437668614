
import {
  computed, defineComponent, ref, toRefs,
} from 'vue';
import webcamProvider from '@/services/WebcamProvider';
import AzureUploader from '@/services/AzureUploader';
import WebcamBox from '@/components/elements/WebcamBox.vue';
import { UploadFollowAlong } from '@/model/Workflow';
import VideoPlayer from '@/components/elements/VideoPlayer.vue';
import motionDB from '@/services/MotionDatabase';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default defineComponent({
  name: 'UploadScreen',
  components: { WebcamBox, VideoPlayer },
  emits: ['upload-canceled', 'upload-completed'],
  props: {
    title: {
      type: String,
      default: 'Video Upload',
    },
    subtitle: {
      type: String,
      default: null,
    },
    prompt: {
      type: String,
      default: 'Upload your video here',
    },
    uploadFilename: {
      type: String,
      default: null,
    },
    maxAttempts: {
      type: Number,
      default: null,
    },
    followAlong: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { followAlong: followAlongRef, maxAttempts } = toRefs(props);

    const state = ref('Record' as 'Record' | 'Review' | 'Success');

    const videoPlayer = ref(null as typeof VideoPlayer | null);
    const attempts = ref(0);
    const followAlongProgress = ref(0);
    const countdownTimerRemaining = ref(0);

    const lastRecordedBlob = ref(null as Blob | null);
    const recordedObjectUrl = ref('');
    const isUploading = ref(false);
    const uploadError = ref(null as null | any);
    const successfullyUploaded = computed(() => state.value === 'Success');

    const videoBaseUrl = computed(() => {
      if (!followAlongRef.value) {
        return '';
      }
      const followData = followAlongRef.value as UploadFollowAlong;
      const dbEntry = motionDB.motionsMap.get(followData.clipName);

      if (!dbEntry) {
        return '';
      }

      return `${dbEntry.videoSrc}#t=${followData.startTime},${followData.endTime}`;
    });

    const maxAttemptsIsReached = computed(() => attempts.value >= (maxAttempts.value ?? Infinity));

    return {
      state,
      webcamProvider,
      attempts,
      webcamStatus: webcamProvider.webcamStatus,
      isRecording: webcamProvider.isRecording,
      lastRecordedBlob,
      recordedObjectUrl,
      isUploading,
      uploadError,
      successfullyUploaded,
      videoPlayer,
      followAlongRef,
      videoBaseUrl,
      followAlongProgress,
      maxAttemptsIsReached,
      countdownTimerRemaining,
    };
  },
  methods: {
    onProgress(progress: number) {
      this.followAlongProgress = progress;
      if (progress >= this.followAlong?.endTime) {
        this.onVideoPlayBackCompleted();
      }
    },
    async onVideoPlayBackCompleted() {
      if (webcamProvider.isRecording.value) {
        this.toggleRecording();
      }
    },
    async toggleRecording() {
      if (this.countdownTimerRemaining > 0) return;

      if (!webcamProvider.isRecording.value) {
        await webcamProvider.startWebcam();

        if (this.followAlong) {
          await this.doCountdown();
        }
        await this.startRecording();

      } else {
        this.lastRecordedBlob = await webcamProvider.stopRecording();
        this.recordedObjectUrl = URL.createObjectURL(this.lastRecordedBlob);
        this.state = 'Review';
        await webcamProvider.stopWebcam();

        if (this.videoPlayer) {
          this.videoPlayer.pauseVideo();
        }
      }
    },
    async doCountdown() {
      // Perform countdown
      this.countdownTimerRemaining = 3;
      await sleep(1000);
      this.countdownTimerRemaining = 2;
      await sleep(1000);
      this.countdownTimerRemaining = 1;
      await sleep(1000);
      this.countdownTimerRemaining = 0;
    },
    async startRecording() {
      this.attempts += 1;
      await webcamProvider.startRecording();

      if (this.followAlongRef && this.videoPlayer) {
        const followData = this.followAlongRef as UploadFollowAlong;
        this.videoPlayer.playVideo(followData.startTime, followData.endTime, followData.clipSpeed);
      }
    },
    async rerecord() {
      this.successfullyUploaded = false;
      this.lastRecordedBlob = null;
      this.recordedObjectUrl = '';
      this.uploadError = null;
      this.state = 'Record';
      await webcamProvider.startWebcam();
    },
    async uploadVideo() {
      if (!this.lastRecordedBlob) return;
      if (this.isUploading) return;

      this.isUploading = true;
      this.uploadError = null;
      const blobName = `${this.$props.uploadFilename}.webm`;

      try {
        await AzureUploader.upload(this.lastRecordedBlob, blobName);
      } catch (e) {
        console.error('Error uploading video', e);
        this.uploadError = e;
      }

      if (this.uploadError === null) {
        this.state = 'Success';
      }

      this.isUploading = false;
    },
  },
});

