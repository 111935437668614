
import { defineComponent } from 'vue';
import MiniLessonPlayer from '@/components/elements/MiniLessonPlayer.vue';
import MiniLesson from '@/model/MiniLesson';

const miniLesson: MiniLesson = {
  _id: 'f60a2a6c-6324-4cb0-8034-38f3ac077a17',
  header: {
    clipName: 'renegade',
    lessonTitle: '65% Practice',
  },
  segmentBreaks: [
    0,
    2.3333333333333335,
    7.7,
    12.233333333333333,
  ],
  activities: [
    {
      title: 'Refresher',
      startTime: 0,
      endTime: 12.233333333333333,
      demoVisual: 'video',
      userVisual: 'none',
      focusedSegments: [
        0,
        1,
        2,
      ],
      pauses: [],
      practiceSpeed: 0.5,
      startInstruction: "We're going to speed it up a bit now",
      timedInstructions: [],
      endInstruction: "Next you'll try it at this speed WITHOUT the video!",
      playingInstruction: 'Follow along',
    },
    {
      title: 'No Video',
      startTime: 0,
      endTime: 12.233333333333333,
      demoVisual: 'skeleton',
      userVisual: 'video',
      focusedSegments: [
        0,
        1,
        2,
      ],
      pauses: [],
      startInstruction: 'Follow along with the teacher skeleton!',
      timedInstructions: [],
      practiceSpeed: 0.65,
      endInstruction: "Repeat until you've got it!",
      playingInstruction: '',
    },
  ],
  source: 'builtin',
};

export default defineComponent({
  components: {
    MiniLessonPlayer,
  },
  data() {
    return {
      miniLesson,
    };
  },
});
